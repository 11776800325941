import React from 'react';
import { ProGallery } from 'pro-gallery';
import { getProGalleryStyles } from '@wix/photography-client-lib/dist/src/utils/proGalleryStyleBuilder';
import window from '@wix/photography-client-lib/dist/src/sdk/windowWrapper';
import experiments from '@wix/photography-client-lib/dist/src/sdk/experimentsWrapper';
import './WixStyles.scss';
import '../FullscreenWrapper/WixStyles.scss';
import './GalleryWrapper.scss';
import { utils } from '../../utils';
import { parseStyleParams } from '@wix/photography-client-lib/dist/src/fullscreen/parseStyleParams.js';
import { resizeMediaUrl } from '@wix/photography-client-lib/dist/src/item/itemResizer';
import EventHandler from '../helpers/eventHandler';
import SiteHelper from '../helpers/siteHelper';
import FullscreenHelper from '../helpers/fullscreenHelper';
import LogHelper from '../helpers/logHelper';
import ItemsHelper from '../helpers/itemsHelper';
import AccessibilityHelper from '../helpers/accessibilityHelper';

const PgContext = React.createContext({});

export default class ProGallerySantaWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullscreen: {
        clickedIdx: -1,
        fullscreenAnimating: false,
        directFullscreenItem: this.props.directFullscreenItem,
        hasFullscreenElement: false,
      },
      isAccessible: false,
      itemsLoveData: {},
    };

    this.FullscreenElement = null;

    this.siteHelper = new SiteHelper(this, props);
    this.logHelper = new LogHelper(this, props);
    this.itemsHelper = new ItemsHelper(this, props);
    this.fullscreenHelper = new FullscreenHelper(this, props);
    this.accessibilityHelper = new AccessibilityHelper(this, props);
    this.eventHandler = new EventHandler(this, props);

    this.onNewProps(props);
  }

  componentDidMount() {
    this.eventHandler.initComponentDidLayout();
    this.siteHelper.loadUserFonts();
    this.itemsHelper.initItemActions();
    this.accessibilityHelper.initAccessibility();

    this.onNewProps(this.props);

    import('../FullscreenWrapper/FullscreenWrapper').then(
      ProFullscreenWrapper => {
        this.FullscreenElement = ProFullscreenWrapper.default;
        const fullscreen = Object.assign({}, this.state.fullscreen, {
          hasFullscreenElement: true,
        });
        this.setState({ fullscreen });
      },
    );
  }

  componentWillReceiveProps(props) {
    this.onNewProps(props);
  }

  componentWillUnmount() {
    this.accessibilityHelper.cleanupAccessibility();
  }

  onNewProps(props) {
    this.siteHelper.update(props);
    this.fullscreenHelper.update(props);
    this.logHelper.update(props);
    this.itemsHelper.update(props);
    this.accessibilityHelper.update(props);
  }

  render() {
    const { queryParams, notInView } = this.props;

    if (
      window.isSSR &&
      ((experiments && experiments('specs.pro-gallery.skipSsr') === 'true') ||
        (queryParams && queryParams.skipPgSsr === 'true'))
    ) {
      console.error('Skipping Pro Gallery SSR!', this.props);
      return <div />;
    }

    if (utils.isSSR() && notInView) {
      if (utils.isVerbose()) {
        console.log('PG not in view, skipping');
      }
      return <div id="pg-not-in-view" />;
    }

    if (utils.isVerbose()) {
      console.log('Pro Gallery wrapper!', this.props);
      console.count('[OOISSR] proGallery ooi wrapper render');
    }
    const {
      id,
      galleryId,
      dimensions: { width, height },
      style,
      formFactor,
      forceHover,
      manualStyleParams,
      baseUrl,
      viewMode,
    } = this.props;

    const isPremiumSite =
      baseUrl && baseUrl.includes && !baseUrl.includes('.wixsite.com'); // this is a hack until we have a normal way to know
    const isMobile =
      this.siteHelper.isMobileDevice(formFactor) || utils.isMobile();

    const { styleParams } = style;
    this.pgGalleryStyles = getProGalleryStyles(
      { ...styleParams, manual: manualStyleParams },
      { isMobile },
    );
    this.pgGalleryStyles.isAccessible = this.state.isAccessible;

    if (
      isMobile &&
      !window.isSSR &&
      typeof this.pgGalleryStyles.gallerySize === 'number'
    ) {
      //compensate for wix's 320px fix for mobile
      const fixRatio = 320 / window.screen.width;
      this.pgGalleryStyles.gallerySize *= fixRatio;
      this.pgGalleryStyles.imageMargin *= fixRatio;
    }
    this.pgProps = {
      domId: id,
      galleryId,
      allowSSR: true,
      container: { width, height },
      forceHover,
      noFollowForSEO: !isPremiumSite,
      viewMode: this.siteHelper.parseViewMode(viewMode),
      scrollingElement: this.siteHelper.getScrollingElement(),
      itemsLoveData: this.state.itemsLoveData,
      resizeMediaUrl,
    };

    if (this.itemsHelper.pgItemsProps().length === 0) {
      this.eventHandler.setZeroItems();
    }

    const pgContextValue = { isMobile };
    const dom = [
      <PgContext.Provider key="provider" value={pgContextValue}>
        <ProGallery
          key="pro-gallery"
          ref={node => (this.node = node)}
          styles={this.pgGalleryStyles}
          eventsListener={this.eventHandler.handleEvent}
          // customInfoRenderer={props => {
          //   return <div>customInfoRenderer {props.idx}</div>;
          // }}
          {...this.pgProps}
          {...this.itemsHelper.pgItemsProps()}
        />
      </PgContext.Provider>,
    ];
    if (this.state.fullscreen.hasFullscreenElement && this.FullscreenElement) {
      const ProFullscreenWrapper = this.FullscreenElement;
      dom.push(
        <ProFullscreenWrapper
          {...this.pgProps}
          {...this.fullscreenHelper.fullscreenItemsProps()}
          key="pro-fullscreen"
          styleParams={parseStyleParams(styleParams)}
          scrollTo={this.props.scrollTo}
          fullscreenAnimating={this.state.fullscreen.fullscreenAnimating}
          fullscreenIdx={
            this.state.fullscreen.directFullscreenItem &&
            this.state.fullscreen.directFullscreenItem.itemId
              ? 0
              : this.state.fullscreen.clickedIdx
          }
          closeFullscreen={this.fullscreenHelper.animatedCloseFullscreen}
          toggleBrowserFullscreen={
            this.fullscreenHelper.toggleBrowserFullscreen
          }
          onLinkNavigation={this.props.onLinkNavigation}
          itemActions={this.itemsHelper.getItemActions()}
          biLogger={this.logHelper.getBiLogger()}
          updateCurrentItem={this.fullscreenHelper.updateFullscreenCurrentItem}
        />,
      );
    }

    return (
      <div data-key={`gallery-wrapper-${id}`} key={`gallery-wrapper-${id}`}>
        {dom}
      </div>
    );
  }
}
